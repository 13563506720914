.progress-bar-container {
	position: relative;
	width: 100%;
	height: 20px;
	background-color: #ffff; 
	border-radius: 10px; 
  }
  
  .progress-bar {
	height: 100%;
	border-radius: 10px; 
	transition: width 0.5s ease-in-out; 
  }
  
  .triangle {
	position: absolute;
	bottom: calc(100% - 1px);
	transform: translateX(-50%) rotate(180deg);
	left: 0;
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid #BFA285;
  }

  .pts{
	transform: translateY(50%) rotate(180deg);
  }
  