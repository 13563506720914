html {
	scroll-behavior: smooth;
	overflow-x: hidden !important;
}

body {
	font-family: 'montserrat', sans-serif;
	overflow-x: hidden !important;
}

h1 {
	font-size: 3rem;
	line-height: 4rem;
}

p {
	font-family: 'Montserrat', sans-serif;
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.title {
	font-weight: 700;
	font-family: Oswald, sans-serif;
	--tw-text-opacity: 1;
	color: rgb(125 2 2 / var(--tw-text-opacity));
}

.navbar {
	transition: background-color 0.3s ease;
}

.underline-animation {
	display: inline-block;
	position: relative;
}

.underline-animation::after {
	content: '';
	position: absolute;
	width: 100%;
	transform: scaleX(0);
	height: 1.5px;
	bottom: -1px;
	left: 0;
	transform-origin: bottom right;
	transition: transform 0.3s ease-out;
	background-color: #ffffff;
}

.underline-animation-red::after {
	content: '';
	position: absolute;
	width: 100%;
	transform: scaleX(0);
	height: 1.5px;
	bottom: -1px;
	left: 0;
	transform-origin: bottom right;
	transition: transform 0.3s ease-out;
	background-color: #6f0b09;
}

.underline-animation:hover::after,
.underlined::after {
	transform: scaleX(1);
	transform-origin: bottom left;
}
