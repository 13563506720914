@media (prefers-reduced-motion: no-preference) {
	.logo {
		animation: Logo-spin infinite 20s linear;
	}
}

@keyframes Logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
