.button {
	width: fit-content;
	text-align: center;
	border-radius: 5rem;
	padding: 0.75rem 2rem;

	position: relative;
	z-index: 1;

	/* Content (text) */
	color: #fff;
	font-family: Oswald, sans-serif;
	font-weight: 400;
	font-size: 1.125rem /* 18px */;
	line-height: 1.75rem /* 28px */;
	cursor: pointer;
}

.button.transparent_bg {
	color: #7d0202;
	border: solid 3px transparent;
	background: linear-gradient(225deg, #8c1c16, #bc7059);
	background-origin: border-box;
	background-clip: border-box;
	box-shadow: 2px 1000px 1px #fffdf5 inset;

	transition: 300ms ease-out;
	-webkit-transition: 300ms ease-out;
	transition-property: box-shadow, color;
	-webkit-transition-property: box-shadow, color;
}

.button.gray_bg {
	color: #000000;
	background: #d9d9d9;
}

.button::before {
	border-radius: 5rem;

	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: linear-gradient(225deg, #8c1c16, #bc7059);
	z-index: -1;
	opacity: 0;
	transition: opacity 300ms ease-out;
	-webkit-transition: opacity 300ms ease-out;
}

.button:hover {
	color: #fff;
	box-shadow: none;
}

.button:hover::before {
	opacity: 1;
}

.circleButton {
	width: 3rem;
	height: 3rem;
	text-align: center;
	border-radius: 100%;
	padding: 0.75rem;

	position: relative;
	z-index: 1;

	color: #fff;
	font-family: Oswald, sans-serif;
	font-weight: 400;
	font-size: 1.125rem;
	line-height: 1.75rem;
	cursor: pointer;
}

.circleButton::before {
	border-radius: 100%;

	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: linear-gradient(225deg, #8c1c16, #bc7059);
	z-index: -1;
	opacity: 0;
	transition: opacity 300ms ease-out;
	-webkit-transition: opacity 300ms ease-out;
}

.circleButton:hover {
	color: #fff;
	box-shadow: none;
}

.circleButton:hover::before {
	opacity: 1;
}

@media only screen and (max-width: 639px) {
	.button {
		padding: 0.4rem 1.7rem;
		font-size: 1rem;
	}
}

@media only screen and (min-width: 640px) and (max-width: 1039px) {
	.button {
		padding: 0.6rem 2rem;
		font-size: 1.3rem;
	}
}

.landing-button {
	color: #fff;
	/* padding: 0.75rem 2rem; */
	display: inline-block;
	position: relative;
	z-index: 0;
	background: none;
	border: none;
	box-shadow: '2px 1000px 1px #fff inset';
	transition: 'all 0.3s ease-out';
}

.landing-button:before {
	content: '';
	position: absolute;
	z-index: -1;
	inset: 0;
	padding: 2.5px;
	border-radius: 5rem;
	background: linear-gradient(225deg, #8c1c16, #bc7059);
	-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	-webkit-mask-composite: xor;
	mask-composite: exclude;
	transition: 'all 0.3s ease-out';
}

.landing-button:hover:before {
	mask-composite: initial;
}


