@tailwind base;
@tailwind components;
@tailwind utilities;

/* Montserrat */
@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url('./fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
}

/* Oswald */
@font-face {
	font-family: 'Oswald';
	src: local('Oswald'), url('./fonts/Oswald/Oswald-VariableFont.ttf') format('truetype');
}

/* Inter */
@font-face {
	font-family: 'Inter';
	src: local('Inter'), url('./fonts/Inter/Inter-VariableFont.ttf') format('truetype');
}

/* Poppins Regular */
@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Poppins Medium */
@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins/Poppins-Medium.ttf') format('truetype');
    font-weight: 500; /* Medium weight is typically 500 */
    font-style: normal;
}

/* Poppins SemiBold */
@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600; /* SemiBold weight is typically 600 */
    font-style: normal;
}

/* Poppins Bold */
@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins/Poppins-Bold.ttf') format('truetype');
    font-weight: bold; /* Bold weight is typically 700 */
    font-style: normal;
}

/* Poppins Italic */
@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins/Poppins-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

/* ... additional @font-face rules for other weights and styles ... */

/* Poppins Black */
@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins/Poppins-Black.ttf') format('truetype');
    font-weight: 900; /* Black weight is typically 900 */
    font-style: normal;
}
